import TwilioDiagnosticsAdapter from './twilio_diagnostics_adapter';
import AcsDiagnosticsAdapter from './acs_diagnostics_adapter';
import TestDiagnosticsAdapter from './test_diagnostics_adapter';

export function getDiagnosticsAdapter(serviceIdentifier, urlVideoToken, urlStunTurnToken, acsAccessToken) {
    switch (serviceIdentifier) {
        case 'twilio':
            return new TwilioDiagnosticsAdapter(urlVideoToken, urlStunTurnToken);
        case 'acs':
            return new AcsDiagnosticsAdapter(acsAccessToken);
        case 'test':
            return new TestDiagnosticsAdapter();
        default:
            throw new Error('Unsupported video call service');
    }
}
